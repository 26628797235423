<template>
    <div id="dashboardsList" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="dashboards"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="analytics_dashboardsSubTitle"></app-sitetitle>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper">
                                    <router-link v-if="!isEmptyList" :to="{ name: 'newDashboard' }" id="analytics_createNewDashboardButton" class="btn btn-brand btn-bold btn-sm btn-icon-h kt-margin-l-10">
                                        <i class="flaticon2-plus kt-margin-r-5"></i>
                                        {{ $t("analytics_createNewDashboard") }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <app-accesserrorportlet ref="accessErrorPortlet" v-show="!accessGranted" activeItem="dashboards" :siteId="siteId"></app-accesserrorportlet>

                        <div v-if="accessGranted" class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile ">
                                        <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z" fill="#000000" fill-rule="nonzero" />
                                                            <path d="M8.7295372,14.6839411 C8.35180695,15.0868534 7.71897114,15.1072675 7.31605887,14.7295372 C6.9131466,14.3518069 6.89273254,13.7189711 7.2704628,13.3160589 L11.0204628,9.31605887 C11.3857725,8.92639521 11.9928179,8.89260288 12.3991193,9.23931335 L15.358855,11.7649545 L19.2151172,6.88035571 C19.5573373,6.44687693 20.1861655,6.37289714 20.6196443,6.71511723 C21.0531231,7.05733733 21.1271029,7.68616551 20.7848828,8.11964429 L16.2848828,13.8196443 C15.9333973,14.2648593 15.2823707,14.3288915 14.8508807,13.9606866 L11.8268294,11.3801628 L8.7295372,14.6839411 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("analytics_dashboardsList") }}
                                                </h3>
                                            </div>
                                            <div v-if="!isEmptyList" class="kt-portlet__head-toolbar">
                                                <div class="kt-portlet__head-wrapper">
                                                    <div class="kt-portlet__head-toolbar">
                                                        <div class="row align-items-center">
                                                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
                                                                <div class="kt-input-icon kt-input-icon--left">
                                                                    <input type="text" :placeholder="$t('common_searchByName')" @keyup="onSearch" v-model="searchedValue" id="dashboards_searchInput" class="searchInputDatatable form-control form-control-sm width270" />
                                                                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                                        <span><i class="la la-search"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--begin emptyList component-->
                                        <app-emptylist targetObjectName="DASHBOARD" mainTextKey="analytics_dashboardNotFound" subTextKey="analytics_dashboardNotFoundSubLabel" imgName="empty_dashboard.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
                                        <!--end emptyList component-->
                                        <div class="kt-portlet__body kt-portlet__body--fit">
                                            <!--begin: Datatable -->
                                            <div class="kt-datatable" id="dashboards_datatable"></div>
                                            <!--end: Datatable -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "./../utils/emptylist.vue";
import SiteTitle from "./../site/widgets/sitetitle.vue";
import AccessErrorPortlet from "./../utils/accesserrorportlet.vue";
import moment from "moment";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            userDashboardsTable: null,
            isEmptyList: false,
            isMounted: false,
            searchedValue: ""
        };
    },
    created: function() {
        console.log("Component(dashboards)::created() - called");
        if (this.createAnotherDashboard) {
            this.$router.push({ name: "newDashboard" });
        } else {
            // Load this site's dashboards related to this user.
            this.getDashboardsOfUserOnSite(this.siteId);
        }
    },
    mounted: function() {
        console.log("Component(dashboards)::mounted() - Init metronic layout");
        this.isMounted = true;
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(dashboards)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        dashboardsOfUserOnSite: function() {
            console.log("Component(dashboards)::watch(dashboardsOfUserOnSite) called");
            var self = this;
            if (this.dashboardsOfUserOnSite != null) {
                // If site table has been already build, destroy the table before creating a fresh instance !
                if (this.userDashboardsTable) {
                    $("#dashboards_datatable")
                        .KTDatatable()
                        .destroy();
                }

                // According to source list length, show datatable or empty list icon+text
                if (this.dashboardsOfUserOnSite.length > 0) {
                    // There is data => Hide empty list icon+text
                    this.isEmptyList = false;

                    const options = {
                        data: {
                            type: "local",
                            source: commonVueHelper.preventHtmlInjection(this.dashboardsOfUserOnSite),
                            pageSize: 5,
                            saveState: {
                                webstorage: false
                            }
                        },
                        layout: commonVueHelper.getLayoutOptions(),
                        translate: commonVueHelper.getTranslateOptions(),
                        toolbar: commonVueHelper.getToolbarOptions(),
                        sortable: !0,
                        pagination: !0,
                        columns: [
                            {
                                field: "name",
                                title: i18n.t("analytics_dashboardName"),
                                textAlign: "center",
                                template: function(row) {
                                    return '\
                                        <div class="kt-user-card-v2">\
                                            <div class="kt-user-card-v2__details openResourceLink">\
                                                <a class="displayDashboardButton kt-link kt-user-card-v2__name" data-dashboardid="' + row.id + '">' + row.name + "</a>\
                                            </div>\
                                        </div>\
                                    ";
                                }
                            },
                            {
                                field: "updatedAt",
                                title: i18n.t("common_lastUpdateDate"),
                                textAlign: "center",
                                sortable: "desc",
                                template: function(row) {
                                    if (row.updatedAt) {
                                        return moment(row.updatedAt).format(commonVueHelper.getDateStringFormatFromLocale(moment().locale()));
                                    }
                                }
                            },
                            {
                                field: "refreshInterval",
                                title: i18n.t("analytics_dashboardRefreshInterval"),
                                textAlign: "center",
                                template: function(row) {
                                    return self.formatRefreshInterval(row.refreshInterval);
                                }
                            },
                            {
                                field: "widgets",
                                title: i18n.t("analytics_dashboardNumberOfWidgets"),
                                textAlign: "center",
                                template: function(row) {
                                    return String(row.widgets.length);
                                }
                            },
                            {
                                field: "Actions",
                                title: i18n.t("common_actions"),
                                sortable: false,
                                width: 110,
                                overflow: "visible",
                                autoHide: false,
                                textAlign: "center",
                                template: function(row) {
                                    return (
                                        `
                                        <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                        i18n.t("common_edit") +
                                        `" type="button" data-dashboardid="` +
                                        row.id +
                                        `" class="btn btn-outline-brand btn-sm btn-icon editDashboardButton">
                                            <i class="la la-pencil"></i>
                                        </button>
                                        <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                        i18n.t("analytics_dashboardDisplay") +
                                        `" type="button" data-dashboardid="` +
                                        row.id +
                                        `" class="btn btn-outline-brand btn-sm btn-icon displayDashboardButton">
                                            <i class="flaticon-eye"></i>
                                        </button>
                                        <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                        i18n.t("common_delete") +
                                        `" type="button" data-dashboardid="` +
                                        row.id +
                                        `" data-dashboardname="` +
                                        row.name +
                                        `" class="btn btn-outline-danger btn-sm btn-icon deleteDashboardButton">
                                            <i class="la la-trash"></i>
                                        </button>
                                    `
                                    );
                                }
                            }
                        ]
                    };

                    // Now, we are ready to build the table
                    this.userDashboardsTable = $("#dashboards_datatable").KTDatatable(options);
                    // Once table is build, register click events on buttons inside the table
                    this.registerEventsDashboardsListTable();
                } else {
                    // There is no data => Show empty list icon+text
                    // No table process: Comment lines below because table destroy previously
                    this.isEmptyList = true;
                }
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["dashboardsOfUserOnSite", "currentSite", "createAnotherDashboard"]),

        accessGranted: function() {
            if (this.isMounted) {
                return this.$refs.accessErrorPortlet.isAccessGranted();
            } else return true; // Do not display error portlet by default
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getDashboardsOfUserOnSite", "showDeleteModal"]),
        onSearch() {
            if (this.userDashboardsTable) {
                this.userDashboardsTable.search(this.searchedValue, "name");
            }
        },
        onEditDashboard: function(dashboardId) {
            this.$router.push({ name: "editDashboard", params: { dashboardId: dashboardId, siteId: this.siteId } });
        },
        onDisplayDashboardButton: function(dashboardId) {
            this.$router.push({ name: "dashboardWidgets", params: { dashboardId: dashboardId, siteId: this.siteId } });
        },
        registerEventsDashboardsListTable() {
            var self = this;
            $("#dashboards_datatable").on("kt-datatable--on-layout-updated", () => {
                $(".deleteDashboardButton")
                    .off()
                    .on("click", function() {
                        let dashboardId = $(this).attr("data-dashboardid");
                        let dashboardName = $(this).attr("data-dashboardname");
                        console.log("Component(dashboards)::registerEventsDashboardsListTable(deleteDashboardButton) clicked. dashboardId = " + dashboardId);
                        self.showDeleteModal({ textContent: i18n.t("analytics_deleteDashboardConfirmText", { dashboardName: dashboardName }), actionName: "DELETE_DASHBOARD", params: { siteId: self.siteId, dashboardId: dashboardId } });
                    });
                $(".editDashboardButton")
                    .off()
                    .on("click", function() {
                        let dashboardId = $(this).attr("data-dashboardid");
                        console.log("Component(dashboards)::registerEventsDashboardsListTable(editDashboardButton) clicked. DashboardId = " + dashboardId);
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to edit vue
                        self.onEditDashboard(dashboardId);
                    });
                $(".displayDashboardButton")
                    .off()
                    .on("click", function() {
                        let dashboardId = $(this).attr("data-dashboardid");
                        console.log("Component(dashboards)::registerEventsDashboardsListTable(editDashboardButton) clicked. dashboardId = " + dashboardId);
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to dashboard widgets vue
                        self.onDisplayDashboardButton(dashboardId);
                    });
                // Force width pager size
                setTimeout(() => {
                    $(".kt-datatable__pager-size").css("width", "80px");

                    // Init tooltips
                    $('[data-toggle="kt-tooltip"]').tooltip({ trigger: "hover" });
                }, 50);
            });
        },

        formatRefreshInterval(refreshTime) {
            refreshTime = parseInt(refreshTime);
            if (!refreshTime) {
                return "-";
            }
            if (refreshTime >= 3600) {
                return i18n.tc("analytics_RI_hours", Math.floor(refreshTime/3600));
            }

            if (refreshTime >= 60) {
                return i18n.tc("analytics_RI_minutes", Math.floor(refreshTime/60));
            }
            return i18n.tc("analytics_RI_seconds", refreshTime)

        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-sitetitle": SiteTitle,
        "app-accesserrorportlet": AccessErrorPortlet
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
